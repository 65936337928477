import React from 'react'
import './../App.css';
function Process() {
  return (
    <div id="process" style={{   
    backgroundImage:"url('./../assets/1.png')", backgroundRepeat:"no-repeat", backgroundPosition:"110% 20%"}}>
   <div style={{ paddingTop:80, paddingBottom:80, width:"100%", backgroundColor:"#0F0E0E89",  display:"flex", justifyContent:"center", alignItems:'center',  flexDirection:"column", }}>
    <p id="title" style={{fontSize:18}}><span>Project Briefing and Planning (1-2 weeks)</span>: We receive a brief from the client 
    outlining their needs and objectives. This stage involves understanding the client’s requirements, 
    target audience, and project goals. We then formulate a project plan, including timelines, 
    resources, and cost estimates.</p>
    <p id="title"  style={{fontSize:18}}><span>Design and Prototyping (2-4 weeks)</span>: The design team creates wireframes, mockups,
     and prototypes based on the project brief. These designs are reviewed and refined based on feedback 
     from the client.</p>
    <p id="title"  style={{fontSize:18}}><span>Development (6-12 weeks)</span>: Once the designs are approved, the development team starts
     building the app or website. This stage involves coding, integrating APIs, and setting up databases.
      Regular updates are provided to the client to ensure the project is on track.</p>
    <p id="title"  style={{fontSize:18}}><span>Testing and Quality Assurance (1-2 weeks)</span>: The developed app or website undergoes 
    rigorous testing to identify and fix any bugs or issues. This ensures that the final product is
     reliable and meets the client’s expectations.</p>
    <p id="title"  style={{fontSize:18}}><span>Deployment and Handover (2 week)</span>: After successful testing, the app or website is 
    deployed to the live environment. We provide the client with all necessary documentation 
    and conducts a final project review. Post-deployment support is also arranged as per the agreement 
    with the client.</p>
    </div>

</div>
  )
}

export default Process