import React, {   } from 'react'
import './../App.css';
 

function Work() {

 

  return (
    <div id="work" style={{ display:"flex", justifyContent:"center", alignItems:'center', 
    backgroundImage:"url('./../assets/5.png')", backgroundRepeat:"no-repeat",  backgroundPosition:"120% 20%"}}>
    <div style={{width:"100%"}}>
        <div className='works'>
        <div id="sp">
            <h1>APPS</h1>
            <p>We create some interesting things. Some we actually<br/> call Legendary for various internal reason.</p>
        </div> 
        </div>
        <div className='works'>
        <div id="sp">
            <h1>PWA</h1>
            <p>When you want an app but with out the <br/> restrictions that come with it.</p>
        </div> 
        </div>
        <div className='works'>
        <div id="sp">
            <h1>DASHBOARDS</h1>
            <p>Its boring but numbers turned in to <br/>charts is "insert chef's kiss"</p>
        </div> 
        </div>
        <div className='works'>
        <div id="sp">
            <h1>GAMES</h1>
            <p>Not the ones with 3D graphics but<br/> the ones that will keep you entertained</p>
        </div> 
        </div>
</div>
    </div>
  )
}

export default Work