
import { useState, useRef } from 'react';
import './App.css';
import About from './other/about';
import Contact from './other/Contact';
import Process from './other/Process';
import Work from './other/Work';
import Projects from './other/Projects';
import { motion } from "framer-motion"
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import Gallery from './other/Gallery';

gsap.registerPlugin(useGSAP);

function App() {

  const [page, setpage] = useState(0) 

  return (
    <div>
 

    <div id="content">
        <div style={{position:"fixed", top:20, right:20, left:20}}>

            <div id="logo" >
            <a href='#'><img   src='./assets/logo.svg' style={{width:45}} alt="imagine" /></a>
              <div id="right"> 
                  <h4  ><a href='#process'>OUR PROCESS</a></h4>
                  <h4  ><a href='#gallery'>SHOWCASE</a></h4>
              </div>
            </div>
        </div>
      
      <div style={{display:"flex", justifyContent:"center", alignItems:"center",  height:"100vh"}}>
       <img src='./assets/studio.svg' style={{ width: "70%", 
  objectFit: "cover", 
  zIndex: -1}} alt="imagine" />
 
      {/* <video  style={{ width: "100vw",
  height: "100vh",
  objectFit: "cover",
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: -1}} autoplay muted loop  controls>
      <source src="./assets/gltich_1.mp4" type="video/mp4"/>
      Your browser does not support the video tag.
    </video> */}
      {/* <div id="hero">
          <div id="heroCard">
              <img src='./assets/IMAGINE.svg' style={{width:"100%"}} alt="imagine" />
          </div>
          <div id="heroCard">
          <img src='./assets/DESIGN.svg' style={{width:"100%"}} alt="imagine" />
          </div>
          <div id="heroCard">
          <img src='./assets/DEVELOP.svg' style={{width:"100%"}} alt="imagine" />
          </div>
      </div> */}
</div> 
      <div id="bottomContent">
        <h1 onClick={()=>setpage(1)} id={page === 1 && "active"}>GAMES</h1>
        <h1  onClick={()=>setpage(2)} id={page === 2 && "active"}>APPS</h1>
        <h1  onClick={()=>setpage(3)} id={page === 3 && "active"}>WEB APPS</h1>
        <h1  onClick={()=>setpage(4)} id={page === 4 && "active"}>Experiments</h1>
      </div>


      <div id="other">
      <div>
          <h4  ><a href='#about'>ABOUT</a></h4>
          <h4  ><a href='#work'>WORK</a></h4>
          <h4  ><a href='#contact'>CONTACT</a></h4>

      </div>

          <h4 style={{display:"flex", justifyContent:"center", alignItems:"center"}}> 
           SCROLL <motion.img src='./assets/down.svg'
                animate={{
        
        rotate: [0, 0, 180, 180, 0],
        // borderRadius: ["0%", "0%", "50%", "50%", "0%"]
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1
      }}
            style={{width:"56px", marginLeft:"20px", marginRight:"20px"}} alt="imagine" /> DOWN </h4>

      <div style={{display:"flex", flexDirection:"column"}}>
      <a href='mailto:nextradium@gmail.com'><img src='./assets/email.svg' style={{width:30, marginBottom:30}} alt="imagine" /></a>
      <a href='tel:+26076492828'><img src='./assets/phone.svg' style={{width:30}} alt="imagine" /></a>
      </div>
         
      </div>

    </div>
    <About />
    <Work />
    <Gallery />
    <Process />
    <Projects />
    <Contact />
    </div>
  );
}

export default App;
