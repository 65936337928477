import React from 'react'

function Projects() {
  return (
    <div id="work" style={{ display:"flex", justifyContent:"center", alignItems:'center', 
    backgroundImage:"url('./../assets/3.png')", backgroundRepeat:"no-repeat",  backgroundPosition:"-12% 50%"}}>
    
    <div style={{width:"100%"}}>
      
        <div className="works">
        <div id="sp">
            <h1>ONELIFE</h1>
            <p>Developed an Android/IOS app for <br/>OneLife Assurance Zambia.</p>
        </div> 
        </div>
       
        <div className="works">
        <div id="sp">
            <h1>FINDME</h1>
            <p>Your one stop shop for the best <br/>locations and events in Zambia.</p>
        </div> 
        </div>
       
        <div className="works">
        <div id="sp">
            <h1>TIYENI</h1>
            <p>A different take on bus booking.</p>
        </div> 
        </div>
        
        <div className="works">
        <div id="sp">
            <h1>PRFLECNVS</h1>
            <p>We got the best of a jobs portal and the <br/> best of social and created one platform.</p>
        </div> 
        </div>
        
        <div className="works">
        <div id="sp">
            <h1>XPERIMNTS</h1>
            <p>When there are no strings attached <br/>we build some things for fun..</p>
        </div> 
        </div>

        <div className="works">
        <div id="sp">
            <h1>...</h1>
            <p>There is more, give us a call <br/>and we will walk you through.</p>
        </div> 
        </div>
</div>
    
    </div>
  )
}

export default Projects